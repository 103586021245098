<template>
  <div>
    <a-card :bordered="false">
      <div slot="title">
        <span>用戶统计</span>
        <a-select placeholder="類型" style="width: 100px;margin-left: 20px" @change="getSumData">
          <a-select-option value="1">本日</a-select-option>
          <a-select-option value="2">本周</a-select-option>
          <a-select-option value="3">本月</a-select-option>
          <a-select-option value="4">本年</a-select-option>
        </a-select>

      </div>
      <div class="box">
        <div class="box-item box-item-border">
          <span class="box-item-name">登录用戶数</span>
          <div class="box-item-money">
            <span>{{object.loginUserNum}}</span><span class="box-item-money-text"></span>
          </div>
        </div>
        <div class="box-item box-item-border">
          <span class="box-item-name">新增用戶数</span>
          <div class="box-item-money">
            <span>{{object.registerUserNum}}</span><span class="box-item-money-text"></span>
          </div>
        </div>
      </div>
    </a-card>

  </div>
</template>

<script>


  import request from "@/utils/request";

  export default {
    name: "UserAnalysis",
    components: {

    },
    data() {

      return {
        indexStyle:1,
        dateRange:[],
        range:[],
        visitFields:['jeecg','jeebt'],
        visitInfo:[
          { type: '01号', jeecg: 7.0, jeebt: 3.9 },
          { type: '02号', jeecg: 6.9, jeebt: 4.2 },
          { type: '03号', jeecg: 9.5, jeebt: 5.7 },
          { type: '04号', jeecg: 14.5, jeebt: 8.5 },
          { type: '05号', jeecg: 18.4, jeebt: 11.9 },
          { type: '06号', jeecg: 21.5, jeebt: 15.2 },
          { type: '07号', jeecg: 25.2, jeebt: 17.0 },
          { type: '08号', jeecg: 26.5, jeebt: 16.6 },
          { type: '09号', jeecg: 23.3, jeebt: 14.2 },
          { type: '10号', jeecg: 18.3, jeebt: 10.3 },
          { type: '11号', jeecg: 13.9, jeebt: 6.6 },
          { type: '12号', jeecg: 9.6, jeebt: 4.8 }
        ],
        // 1昨日 2近7天 3近30天
        timeOptions:[
          {
            label:'昨日',
            value:1
          },{
            label:'近7天',
            value:2
          },{
            label:'近30天',
            value:3
          },
        ],
        timeOptions1:[
          {
            label:'今天',
            value:1
          },{
            label:'近7天',
            value:2
          },{
            label:'近30天',
            value:3
          },
        ],
        // 1視頻 2图集
        dataTypeOptions:[
          {
            label:'視頻',
            value:1
          },{
            label:'图集',
            value:2
          },
        ],
        jyTimeValue:1,  // 数据总览
        homeTransData: {},
        ssTimeValue:1, // 实时趋势
        dataTypeValue:1,
        ssCharData: {
          title:'趋势图',
          legendData: [
            {
            name: '作品瀏覽量',
            color: '#75baff',
            areaColor: 'rgba(117, 186, 255, .2)'
          },
            {
            name: '主页访问数',
            color: '#43c2bf',
            areaColor: 'rgba(67, 194, 191, .2)'
          },
            {
              name: '作品點贊數',
              color: '#43c2bf',
              areaColor: 'rgba(67, 194, 191, .2)'
            },
            {
              name: '評論数',
              color: '#43c2bf',
              areaColor: 'rgba(67, 194, 191, .2)'
            },
            {
              name: '分享数',
              color: '#43c2bf',
              areaColor: 'rgba(67, 194, 191, .2)'
            },
            {
              name: '新增粉丝数',
              color: '#43c2bf',
              areaColor: 'rgba(67, 194, 191, .2)'
            },
          ],
          expectedData: [],
          actualData: []
        },
        rewardTimeValue:1, // 打赏榜
        rewardBarData: {
          seriesName: '打赏榜',
          seriesData: [],
          xAxisData: [],
            color:'#a5d33e'
        },
        fansTimeValue:1, // 人气榜
        fansBarData: {
          seriesName: '人气榜',
          seriesData: [],
          xAxisData: [],
          color:'#a5d33e'
        },
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        object:{},
      }
    },
    mounted() {
/*      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
      this.dateRange = [moment(start).format('YYYY-MM-DD'),moment(end).format('YYYY-MM-DD')]
      this.range = [moment(start).format('YYYY-MM-DD'),moment(end).format('YYYY-MM-DD')]*/
      this.getSumData(1);
    },
    methods:{
      getSumData(type){
        let data = {type:type}
        request({
          url: '/user/user/getSumData',
          method: 'get',
          params: data
        }).then(res => {
          if (res.success){
            this.object = res.data
          }else{
            this.$message.error(res.msg)
          }
        })
      },
      download(fileName){
        if(this.range.length === 0) return this.$message.error("请选择日期")
        let data = {begin:this.range[0],end:this.range[1]}
        let url = '/user/user/downloadGetSumData'
        this.down(fileName,url,data)
      },
      /**
       * @desc 切换数据总览参数
       */
      handleChangeHomeData(value) {
        console.log(`selected ${value}`);
        this.jyTimeValue = value;
        this.getHomeTransData();
      },
      /**
       * @desc 切换实时趋势参数
       */
      handleChangeLine(value) {
        console.log(`selected ${value}`);
        this.ssTimeValue = value;
        this.queryLineChart();
      },
      /**
       * @desc 切换实时趋势参数
       */
      handleChangeLine1(value) {
        console.log(`selected ${value}`);
        this.dataTypeValue = value;
        this.queryLineChart();
      },
      /**
       * @desc 切换打赏榜
       */
      handleRewardBarChart(value) {
        console.log(`selected ${value}`);
        this.gsTimeValue = value;
        this.queryRewardBarChart();
      },
      /**
       * @desc 切换人气榜
       */
      handleFansBarChart(value) {
        console.log(`selected ${value}`);
        this.gsTimeValue = value;
        this.queryFansBarChart();
      },
      /**
       * @desc 首頁数据汇总
       */
      getHomeTransData(){
        if(this.dateRange.length === 0){
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          this.dateRange = [moment(start).format('YYYY-MM-DD'),moment(end).format('YYYY-MM-DD')]
        }
        getAction('/brisk/brisk/list',{
          beginTime:this.dateRange[0],endTime:this.dateRange[1]
        }).then(res => {
          console.log('首頁数据汇总>>>>>',res);
          if(res.success){
            let temp = res.result[0]
            this.homeTransData = temp
          }else{
            this.$message.error(res.message);
          }
        })
      },
      /**
       * @desc 查詢实时趋势图
       */
      queryLineChart(){
        let that = this;
        getAction('/analysisData/analysisData/productionDataOverviewSpecifyRange',{
          time: this.ssTimeValue,
          type: this.dataTypeValue,
          category: -1
        }).then(res => {
          console.log('实时趋势图>>>>>',res);
          this.ssCharData = {
            legendData: [
              {
                name: '作品瀏覽量',
                color: '#75baff',
                areaColor: 'rgba(117, 186, 255, .2)'
              },
              {
                name: '主页访问数',
                color: '#43c2bf',
                areaColor: 'rgba(67, 194, 191, .2)'
              },
              {
                name: '作品點贊數',
                color: '#43c2bf',
                areaColor: 'rgba(67, 194, 191, .2)'
              },
              {
                name: '評論数',
                color: '#43c2bf',
                areaColor: 'rgba(67, 194, 191, .2)'
              },
              {
                name: '分享数',
                color: '#43c2bf',
                areaColor: 'rgba(67, 194, 191, .2)'
              },
              {
                name: '新增粉丝数',
                color: '#43c2bf',
                areaColor: 'rgba(67, 194, 191, .2)'
              },
            ],
            expectedData: [],
            actualData: []
          }
          if(res.success){
            let result = res.result.records || [];
            if(result.length>0){
              let liuLan = []; // 作品浏览
              let zhuYe = []; // 主页访问数
              let dianZan = [] // 点赞
              let pingLun = [] // 評論
              let fenXiang = [] // 分享
              let fenSi = [] // 粉丝
              let dateArr = []
              result.forEach(item => {
                if(item.category == 1){
                  liuLan.push(item.num);
                }else if(item.category == 2){
                  zhuYe.push(item.num);
                }else if(item.category == 3){
                  dianZan.push(item.num);
                }else if(item.category == 4){
                  pingLun.push(item.num);
                }else if(item.category == 5){
                  fenXiang.push(item.num);
                }else if(item.category == 6){
                  fenSi.push(item.num);
                }
                dateArr.push(item.createTime);
              })

              let a = [...new Set(dateArr)];
              that.ssCharData.expectedData = a;
              that.ssCharData.actualData.push(liuLan);
              that.ssCharData.actualData.push(zhuYe);
              that.ssCharData.actualData.push(dianZan);
              that.ssCharData.actualData.push(pingLun);
              that.ssCharData.actualData.push(fenXiang);
              that.ssCharData.actualData.push(fenSi);
            }
            // this.homeTransData = res.result;
          }else{
            this.$message.error(res.message);
          }
        })
      },
      /**
       * @desc 打赏榜
       */
      queryRewardBarChart(){
        let that = this;
        getAction('/production/productionRewardConfig/queryRewardUserList',{
          type: this.gsTimeValue
        }).then(res => {
          console.log('打赏榜>>>>>',res);
          this.rewardBarData= {
            seriesName: '打赏榜',
            seriesData: [],
            xAxisData: [],
            color:'#a5d33e'
          }
          let nickNames = []
          let golds = []
          if(res.success){
            let result = res.result.records || [];
            if(result.length>0){
              debugger
              result.forEach(item => {
                nickNames.push(item.nickName);
                golds.push(item.gold);
              })
            }
          }else{
            this.$message.error(res.message);
          }
          nickNames.reverse()
          golds.reverse()
          that.rewardBarData.xAxisData = nickNames
          that.rewardBarData.seriesData = golds
          console.log('打赏榜>>>>>rewardBarData>>>>',this.rewardBarData);
        })
      },
      /**
       * @desc 人气榜
       */
      queryFansBarChart(){
        let that = this;
        getAction('/user/user/popularityList',{
          type: this.gsTimeValue
        }).then(res => {
          console.log('人气榜>>>>>',res);
          this.fansBarData= {
            seriesName: '人气榜',
            seriesData: [],
            xAxisData: [],
            color:'#a5d33e'
          }
          let nickNames = []
          let fansNums = []
          if(res.success){
            let result = res.result.records || [];
            if(result.length>0){
              result.forEach(item => {
                nickNames.push(item.nickName);
                fansNums.push(item.fansNum);
              })
            }
          }else{
            this.$message.error(res.message);
          }
          nickNames.reverse()
          fansNums.reverse()
          that.fansBarData.xAxisData = nickNames;
          that.fansBarData.seriesData = fansNums;
          console.log('人气榜>>>>>fansBarData>>>>',this.fansBarData);
        })
      },
      //共用下载文件
      down(fileName,url,data){
        downFile(url,data).then(data => {
          if(!data){
            this.$message.warning("文件下载失败")
            return
          }
          if (typeof window.navigator.msSaveBlob !== 'undefined') {
            window.navigator.msSaveBlob(new Blob([data], {type: 'application/vnd.ms-excel'}), fileName + '.xls')
          } else {
            let url = window.URL.createObjectURL(new Blob([data], {type: 'application/vnd.ms-excel'}))
            let link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName + '.xls')
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link); //下载完成移除元素
            window.URL.revokeObjectURL(url); //释放掉blob对象
          }
        })
      },
    }

  }
</script>

<style lang="less" scoped>
.table-calss /deep/ .ant-table-tbody .ant-table-row td{
  padding-top: 0;
  padding-bottom: 0;
}
.table-calss /deep/ th{
  padding-top: 0;
  padding-bottom: 0;
}
.box-margin{
  width: 100%;
  height: 12px;
  background: #f6f6f6;
}
.box-margin-r{
  position: absolute;
  top:0;
  right: -6px;
  height: 100%;
  width: 12px;
  background: #f6f6f6;
}
.box{
  display: flex;
  width: 100%;

  .box-item{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 150px;

    &.box-item-border{
      border-right: 1px dotted #AAAAAA
    }

    .box-item-name{
      color: #303133;
      font-weight: bold;
      margin-bottom: 25px;
    }

    .box-item-money{
      display: flex;

      span{
        color: #303133;

        &.box-item-money-text{
          padding-left: 1px;
        }
      }

    }

  }

}
</style>
